import { getZplByOs } from 'api/zebra';
import {
  createContext,
  FC,
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';
import { Device } from 'zebra-browser-print-wrapper/lib/types';

export type PrinterStatusType = {
  isReadyToPrint: boolean;
  errors: string;
};
enum ZebraSizeType {
  DIEZXDIEZ = '10X10',
  DIEZXQUINCE = '10X15',
}
export const initialSizeOptions = [
  {
    value: ZebraSizeType.DIEZXDIEZ,
    name: ZebraSizeType.DIEZXDIEZ,
  },
  {
    value: ZebraSizeType.DIEZXQUINCE,
    name: ZebraSizeType.DIEZXQUINCE,
  },
];
type ContextType = {
  availablePrinters: Device[] | undefined;
  // defaultPrinter: void | Device;
  // error: any;
  print: (zpl: string) => void;
  // selectDefaultPrinter: (options: string) => void;
  isReady: boolean | null;
  size: ZebraSizeType;
  setSize: (size: any) => void;
  getOsData: (os: string) => Promise<string>;
  error: string;
  refresh: number;
  refreshComponent: () => void;
};

const ZebraPrinterContext = createContext({} as ContextType);

const ZebraProvider: FC = (props) => {
  const [availablePrinters, setAvailablePrinters] = useState<Device[]>([]);
  const [error, setError] = useState('');
  const [size, setSize] = useState(ZebraSizeType.DIEZXQUINCE);
  const [isReady, setIsReady] = useState<boolean | null>(null);
  const [refresh, setRefresh] = useState(0);

  const browserPrint = new ZebraBrowserPrintWrapper();
  useEffect(() => {
    async function getAvailablePrinters(): Promise<void> {
      const printers = await browserPrint.getAvailablePrinters();
      browserPrint.setPrinter(printers[0]);

      const printer = browserPrint.getPrinter();
      browserPrint.setPrinter(printer);

      const status = await browserPrint.checkPrinterStatus();
      setAvailablePrinters(printers);
      if (status.isReadyToPrint) {
        setIsReady(true);
      } else {
        setIsReady(false);
        setError(status.errors);
      }
    }
    getAvailablePrinters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const refreshComponent = useCallback(() => {
    setRefresh(refresh + 1);
  }, [refresh]);

  const getOsData = useCallback(
    async (os: string): Promise<string> => {
      return await getZplByOs(os, size);
    },
    [size]
  );

  const print = useCallback(async (zpl: string) => {
    browserPrint.print(zpl);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const values = useMemo(
    () => ({
      availablePrinters,
      print,
      error,
      getOsData,
      isReady,
      size,
      setSize,
      refresh,
      refreshComponent,
    }),
    [
      availablePrinters,
      error,
      print,
      getOsData,
      size,
      setSize,
      isReady,
      refresh,
      refreshComponent,
    ]
  );

  return <ZebraPrinterContext.Provider value={values} {...props} />;
};

function useZebra(): ContextType {
  const context = useContext(ZebraPrinterContext);
  if (context === undefined) {
    throw new Error('usEzebra must be used within a ZebraProvider');
  }

  return context;
}

export { ZebraProvider, useZebra };
