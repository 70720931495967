export const environment = process.env.REACT_APP_ENV;

export const ACCESS_TOKEN_KEY = '__sessionSSO';
export const REFRESH_TOKEN_KEY = '__refresh-token__';

export const KEY_REGIONS = 'locations-regions';
export const KEY_COMMUNES = 'locations-communes';
export const KEY_AGENCIES = 'locations-agencies';
export const compensationsUrl = process.env.REACT_APP_COMPENSATIONS_URL || '';
export const priceQuoteUrl = process.env.REACT_APP_PRICE_QUOTE_URL || '';
export const problemSolutionUrl = process.env.REACT_APP_PROBLEM_SOLUTION || '';
export const materialRequestUrl = process.env.REACT_APP_MATERIALS || '';

export const apiUrl = process.env.REACT_APP_API_URL;
export const apiKey = process.env.REACT_APP_API_KEY;
export const bucketS3 = process.env.REACT_APP_BUCKET_S3;
export const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL;
export const keycloakAuthUrl = process.env.REACT_APP_KEYCLOAK_AUTH_URL;
export const keycloakClient = process.env.REACT_APP_KEYCLOAK_CLIENT || '';
export const keycloakClientSecret =
  process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET || '';
export const redirectUri = process.env.REACT_APP_REDIRECT_URI || '';

export const inscriptionRedirectUri =
  redirectUri?.concat('/confirm-new-payment') || '';
export const webpayReturnUri = redirectUri?.concat('/payment-order') || '';

export const APIConstants = {
  identity: 'api/pyme2c/backend/v1',
  payments: 'api/cross/payments/v1',
  locations: 'api/cross/locations/v1',
  appraisals: 'api/pyme2c/cotizaciones/v1',
  emissions: 'api/pyme2c/emissions/v1',
  massive_emissions: 'api/pyme2c/massive-emissions/v1',
  pricing: 'api/pyme2c/tarifario/v1',
  monitoring_panel: 'api/pyme2c/tracking-panel/v1',
  problems_solution: 'api/pyme2c/problem-solution/v1',
};

export const cleanTokens = (): void => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export function cleanLocationsStorage(): void {
  window.localStorage.removeItem(KEY_REGIONS);
  window.localStorage.removeItem(KEY_COMMUNES);
  window.localStorage.removeItem(KEY_AGENCIES);
}

export const setAccessToken = (access_token: string): void =>
  window.localStorage.setItem(ACCESS_TOKEN_KEY, access_token);

export const setRefreshToken = (refresh_token: string): void =>
  window.localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);

export const getAccessToken = (): string => {
  const accessToken = window.localStorage.getItem(ACCESS_TOKEN_KEY);

  return accessToken ? `Bearer ${accessToken}` : '';
};

export const getRefreshToken = (): string => {
  const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY);

  return refreshToken || '';
};
