import { ReactNode } from 'react';
import { BxExclamation } from '@bx-design/react-icons';

import styles from './info-box.module.scss';

type Props = {
  title?: ReactNode;
  secondaryText?: ReactNode;
};

function NewShippingLayoutInfoBox({
  title,
  secondaryText,
}: Props): JSX.Element {
  return (
    <div className={styles.boxAlert}>
      <div className={styles.precautionBox}>
        <div className={styles.boxIcon}>
          <BxExclamation color='var(--bx-color-orange)' size='32' />
        </div>
        <div className={styles.content}>
          <div className={styles.text}>{title}</div>
          {secondaryText && (
            <div className={styles.secondaryText}>{secondaryText}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewShippingLayoutInfoBox;
