/* eslint-disable @typescript-eslint/no-unused-vars */
// import { FC, useEffect, useState } from 'react';
// import { HelmetProvider } from 'react-helmet-async';
// import { useAtom } from 'jotai';

// import { useAuth } from 'contexts/auth-context';
// import { useGTM } from 'hooks/use-gtm';
// import UnauthenticatedApp from './unauthenticated-app';
// import AuthenticatedApp from './authenticated-app';
// import { handleLoginResponse } from 'api/auth';
// import { sendEvent } from 'utils/gtm';
// import { loginIsSuccessAtom } from 'atoms/login';
// import axiosInstance from './utils/http-interceptor';

// import type {
//   LoginResponseOk,
//   RefreshTokenResponse,
//   UnionLoginResponse,
//   LoginResponseNok,
//   LoginResponseReject,
//   SocialLoginResponseDataType,
// } from 'types/auth';
// // UNIVERSAL
// import withUniversalSession, {
//   UniversalProvider,
//   useSession,
// } from '@blue-express/bx-lib-universal-frontend';

// const App = (): JSX.Element => {
//   const { getSession } = useSession();
//   const session = getSession();
//   const [, setIsSuccess] = useAtom(loginIsSuccessAtom);
//   const [authorizationOk, setAuthorizationOk] = useState(false);

//   useEffect(() => {
//     if (session) {
//       if (session.ssoToken) {
//         // console.log(session);
//         const data_response = {
//           access_token: session.ssoToken.split(' ')[1],
//           expires_in: 600,
//           refresh_expires_in: 1800,
//           refresh_token: session.ssoRefresh,
//           token_type: 'bearer',
//           id_token: session.idToken,
//           session_state: session.sessionid,
//           scope: 'openid email profile',
//         };
//         // const data_response = {
//         //   access_token:
//         //     'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2MnhfMmwtQzVVaXVSZTVPVGFxUzdFZHJRZFlmMHZpMGlQRXBiV3NFeXpBIn0.eyJleHAiOjE2NDk5NDc4NTUsImlhdCI6MTY0OTk0NzI1NSwianRpIjoiMWZlMjIzM2YtZjU1OC00MDc0LWFlYWUtMzQxNmViMzc2NDRlIiwiaXNzIjoiaHR0cHM6Ly9xYS5zc28uYmx1ZXguY2wvYXV0aC9yZWFsbXMvc3VwZXItYXBwIiwic3ViIjoiNjc2ZWFjZjgtZWEzMS00Y2IwLWIxNGMtN2Y3OWJiMjUyOTAxIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiYngtY2xpZW50Iiwic2Vzc2lvbl9zdGF0ZSI6ImVkNDgwZDc1LWQ0ZDctNGZhMC1hZmRjLTI3MTU2OWVkYTc5ZCIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cHM6Ly9xYS50dWVudmlvLmNsIl0sInNjb3BlIjoib3BlbmlkIGVtYWlsIHByb2ZpbGUiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicm9sZXMiOlsicHltZSJdLCJuYW1lIjoiRW1wcmVzYTAzIFByYWN0aWMgRGV2MDEiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJlbXByZXNhMDMtZGV2MDFAcHJhY3RpYy50ZWMuYnIiLCJnaXZlbl9uYW1lIjoiRW1wcmVzYTAzIFByYWN0aWMiLCJmYW1pbHlfbmFtZSI6IkRldjAxIiwiZW1haWwiOiJlbXByZXNhMDMtZGV2MDFAcHJhY3RpYy50ZWMuYnIifQ.YwqAVkv4UHPcN6AKWKsyXvwBydaEtsEr8g8HMHZmQBdoK7Up7igpnkG7vbKjw39Olgt3LHvA8B90niP8-Ek8cTB24dUDKlyHmwmDHl7A0_ZqmbJiQbCYPnxKu63Vcp_AwI46GXxvVRSjyoaWIWvVSqtq4Xh0vcral367gPUgZeR3vd3a7G3_87MIWYH19DGZAMX6BkCdktsy6O27uu5RGXIyZIsoys8kStEPz_kM2QHrLkrlSMFk9wW0uhiFkO4g1C_DqGLuT58BQOjM3lIdQUFDfS01fsjPMxMY7L8JcrePoCRhGZQqsYy6iY8U8os-TRC9MnaURGXACsfC9DRoow',
//         //   expires_in: 600,
//         //   refresh_expires_in: 1800,
//         //   refresh_token:
//         //     'eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJkY2U1NGM4NS03Njk0LTRjNWEtYTk3OC02NmM3NGZmMjNmYmUifQ.eyJleHAiOjE2NDk5NDkwNTUsImlhdCI6MTY0OTk0NzI1NSwianRpIjoiYjg5MzYzNmUtNmZmZS00ZjYwLWExMGQtNGRhYTQwMzU5ZTM4IiwiaXNzIjoiaHR0cHM6Ly9xYS5zc28uYmx1ZXguY2wvYXV0aC9yZWFsbXMvc3VwZXItYXBwIiwiYXVkIjoiaHR0cHM6Ly9xYS5zc28uYmx1ZXguY2wvYXV0aC9yZWFsbXMvc3VwZXItYXBwIiwic3ViIjoiNjc2ZWFjZjgtZWEzMS00Y2IwLWIxNGMtN2Y3OWJiMjUyOTAxIiwidHlwIjoiUmVmcmVzaCIsImF6cCI6ImJ4LWNsaWVudCIsInNlc3Npb25fc3RhdGUiOiJlZDQ4MGQ3NS1kNGQ3LTRmYTAtYWZkYy0yNzE1NjllZGE3OWQiLCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIn0.H-V-TW8Bjf61mIpPdWeHDebRwApVQD-nPPoD7KY-Ws8',
//         //   token_type: 'bearer',
//         //   id_token:
//         //     'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2MnhfMmwtQzVVaXVSZTVPVGFxUzdFZHJRZFlmMHZpMGlQRXBiV3NFeXpBIn0.eyJleHAiOjE2NDk5NDc4NTUsImlhdCI6MTY0OTk0NzI1NSwiYXV0aF90aW1lIjowLCJqdGkiOiI3NWRhOTc1Ny1jMzc4LTRmYjYtOGMzZi0yMTIyM2QzNzhmMTUiLCJpc3MiOiJodHRwczovL3FhLnNzby5ibHVleC5jbC9hdXRoL3JlYWxtcy9zdXBlci1hcHAiLCJhdWQiOiJieC1jbGllbnQiLCJzdWIiOiI2NzZlYWNmOC1lYTMxLTRjYjAtYjE0Yy03Zjc5YmIyNTI5MDEiLCJ0eXAiOiJJRCIsImF6cCI6ImJ4LWNsaWVudCIsInNlc3Npb25fc3RhdGUiOiJlZDQ4MGQ3NS1kNGQ3LTRmYTAtYWZkYy0yNzE1NjllZGE3OWQiLCJhdF9oYXNoIjoiZ21Kb0pkTktKYld0bHVqLVZiRTc5ZyIsImFjciI6IjEiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmFtZSI6IkVtcHJlc2EwMyBQcmFjdGljIERldjAxIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiZW1wcmVzYTAzLWRldjAxQHByYWN0aWMudGVjLmJyIiwiZ2l2ZW5fbmFtZSI6IkVtcHJlc2EwMyBQcmFjdGljIiwiZmFtaWx5X25hbWUiOiJEZXYwMSIsImVtYWlsIjoiZW1wcmVzYTAzLWRldjAxQHByYWN0aWMudGVjLmJyIn0.KkXxSQSzAdL1yIyEJOwH5HjgGVKTNvDCGtEw3wTygI-kaELyN08onTIZIemtgdKa1HanOXHgfZ0a0kO2M8or8Ib5f-i1GgGQun4GuqDnukDaJ4ZAfWEOtS2auYx-JJBpCVGgwW2l2m2ivnInikuCYbv6tAZalRxopFCfW3YIeVsu6-7EO48UpFetXyLPowVBmdFT54RXHmXcFSXLuEkvqCb9o1wWe8jUrFEPyV7hCP3bdq6aeAMCzPDhJPPDhPf47STantLHwjXoeEVCMoTNUh5duKPnm1yu0co1X97d0EvlyqWOT0Sq9DZcK2cc3qJCXA0EgmZF7ZJUj1V71KiBRw',
//         //   session_state: '11866dde-5597-448d-8dfd-8163c6845ace',
//         //   scope: 'openid email profile',
//         // };
//         // handleLoginResponse(data_response as LoginResponseOk);
//         setAuthorizationOk(true);
//         sendEvent({
//           event: 'login',
//           method: 'email',
//         });
//         setIsSuccess(true);
//         axiosInstance.defaults.headers.common['Authorization'] =
//           session.ssoToken;
//         // window.location.assign('/dashboard');
//       }
//     }
//   }, [session]);

//   // console.log(session);
//   return session ? (
//     <HelmetProvider>
//       {authorizationOk ? <AuthenticatedApp /> : <>Olá</>};
//     </HelmetProvider>
//   ) : (
//     <>teste</>
//   );

//   // <HelmetProvider>
//   //   {authorizationOk ? <AuthenticatedApp /> : <>Olá</>};
//   // </HelmetProvider>
// };

// // export default App;

// export default withUniversalSession(App as any, {
//   env: 'dev',
//   service: 'agencias',
//   storage: 'localStorage',
//   locale: 'es',
// });

import { FC } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { useAuth } from 'contexts/auth-context';
import { useGTM } from 'hooks/use-gtm';
import UnauthenticatedApp from './unauthenticated-app';
import AuthenticatedApp from './authenticated-app';

const App: FC = () => {
  useGTM();
  const { user } = useAuth();

  return (
    <HelmetProvider>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </HelmetProvider>
  );
};

export default App;
