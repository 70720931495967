import { FC } from 'react';
import cs from 'classnames';

import LogoBx from 'components/logo-bx';
import { BxChevronLeft, BxChevronRight } from '@bx-design/react-icons';
import Menu from './menu';
import { useHistory } from 'react-router-dom';
import { menuCollapsed } from 'atoms/dashboard';
import { useAtom } from 'jotai';
import styles from './sidebar.module.scss';

type SidebarType = {
  isOpen: boolean;
};

const Sidebar: FC<SidebarType> = () => {
  const history = useHistory();
  const [collapseMenu, setIsOpenSidebar] = useAtom(menuCollapsed);

  return (
    <div
      className={cs(styles.Left, {
        [styles.LeftIsOpen]: collapseMenu,
      })}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <div className={styles.LogoContainer}>
          <div
            onClick={() => history.push('/dashboard')}
            className='flex justify-center'
          >
            <LogoBx height={collapseMenu ? 40 : 36} />
          </div>
          <div
            onClick={() => setIsOpenSidebar(!collapseMenu)}
            className={cs(styles.buttonContainer, {
              [styles.active]: collapseMenu === true,
              [styles.hidden]: collapseMenu === false,
            })}
          >
            {collapseMenu ? (
              <BxChevronLeft color='var(--bx-color-white)' size='14' />
            ) : (
              <BxChevronRight color='var(--bx-color-white)' size='14' />
            )}
          </div>
        </div>
      </div>
      <Menu isCollapsed={collapseMenu} />
    </div>
  );
};

export default Sidebar;
