import { FC } from 'react';

const GradeIcon: FC = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M2.44509 0H8.63797C9.99079 0 11.0831 1.1 11.0831 2.44V8.61C11.0831 9.95 9.99079 11.05 8.63797 11.05H2.44509C1.10229 11.05 0 9.96 0 8.61V2.44C0 1.1 1.10229 0 2.44509 0ZM8.63797 9.49C9.11897 9.49 9.50978 9.1 9.50978 8.62V2.44C9.50978 1.96 9.11897 1.57 8.63797 1.57H2.44509C1.96409 1.57 1.57327 1.96 1.57327 2.44V8.62C1.57327 9.1 1.96409 9.49 2.44509 9.49H8.63797ZM15.3723 0H21.5552C22.908 0 24.0103 1.1 24.0003 2.44V8.61C24.0003 9.95 22.908 11.05 21.5552 11.05H15.3723C14.0295 11.05 12.9272 9.96 12.9272 8.61V2.44C12.9272 1.1 14.0195 0 15.3723 0ZM21.5652 9.49C22.0462 9.49 22.437 9.1 22.437 8.62V2.44C22.437 1.96 22.0462 1.57 21.5652 1.57H15.3824C14.9014 1.57 14.5105 1.96 14.5105 2.44V8.62C14.5105 9.1 14.9014 9.49 15.3824 9.49H21.5652ZM8.63797 12.6299H2.44509C1.10229 12.6299 0 13.7199 0 15.0699V21.2499C0 22.5999 1.10229 23.6899 2.44509 23.6899H8.63797C9.99079 23.6899 11.0831 22.5899 11.0831 21.2499V15.0699C11.0831 13.7199 9.99079 12.6299 8.63797 12.6299ZM9.50978 21.2399C9.50978 21.7199 9.11897 22.1099 8.63797 22.1099H2.44509C1.96409 22.1099 1.57327 21.7199 1.57327 21.2399V15.0599C1.57327 14.5799 1.96409 14.1899 2.44509 14.1899H8.63797C9.11897 14.1899 9.50978 14.5799 9.50978 15.0599V21.2399ZM15.3723 12.6299H21.5552C22.908 12.6299 24.0103 13.7199 24.0003 15.0699V21.2499C24.0003 22.5899 22.908 23.6899 21.5552 23.6899H15.3723C14.0295 23.6899 12.9272 22.5999 12.9272 21.2499V15.0699C12.9272 13.7299 14.0195 12.6299 15.3723 12.6299ZM21.5652 22.1099C22.0462 22.1099 22.437 21.7199 22.437 21.2399V15.0599C22.437 14.5799 22.0462 14.1899 21.5652 14.1899H15.3824C14.9014 14.1899 14.5105 14.5799 14.5105 15.0599V21.2399C14.5105 21.7199 14.9014 22.1099 15.3824 22.1099H21.5652Z'
        fill='#101010'
      />
    </svg>
  );
};

export default GradeIcon;
