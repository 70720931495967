import { lazy, Suspense, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import LocationProvider from 'contexts/locations-context';
import { TrackingProvider } from 'contexts/tracking/tracking-context';
import { PaymentProvider } from 'contexts/payment-context';
import ShipmentsRoutes from 'routes/shipments';
import AccountRoutes from 'routes/account';
import NewShippingRoutes from 'routes/new-shipping';
import WideLoader from 'components/utils/wide-loader';
import Chatbot from 'components/chatbot';
import PoliciesPage from 'pages/authenticated/policies';
import { usePyme } from 'contexts/pyme/pyme-context';
import { Modal } from 'components/ui-bx/modal';
import { CurrentAccountBody } from 'pages/authenticated/dashboard/current-account-body';
import B2CEmissionsRoutes from 'routes/b2b-emission';
import { ZebraProvider } from 'contexts/zebra-printer-context';

const Dashboard = lazy(() => import('pages/authenticated/dashboard'));
// import Dashboard from 'pages/authenticated/dashboard';

const DashboardB2C = lazy(() => import('pages/authenticated/dashboard-b2c'));
const NotFound = lazy(() => import('pages/not-found'));
const PymeMembers = lazy(() => import('pages/authenticated/pyme/pyme-members'));
const PymeBillingInfo = lazy(
  () => import('pages/authenticated/pyme/billing-info')
);
const PaymentResume = lazy(() => import('pages/authenticated/payment/payment'));
const Tracking = lazy(() => import('pages/authenticated/tracking/tracking'));
const NewBusinessPage = lazy(
  () => import('pages/authenticated/business/new-business')
);
const JoinToBusinessPage = lazy(
  () => import('pages/authenticated/business/join-to-business')
);
const TermsPage = lazy(() => import('pages/authenticated/terms'));
const FrequentQuestion = lazy(
  () => import('pages/authenticated/frequent-question')
);
const PriceQuotePage = lazy(() => import('pages/authenticated/price-quote'));
const PriceQuoteResultPage = lazy(
  () => import('pages/authenticated/price-quote/result')
);
const TipsPage = lazy(() => import('pages/authenticated/tips'));
const CompensationPage = lazy(() => import('pages/authenticated/compensation'));
const ProblemSolutionPage = lazy(
  () => import('pages/authenticated/problem-solution')
);
const MaterialsRequestPage = lazy(
  () => import('pages/authenticated/materials-request')
);
const DangerousPage = lazy(
  () => import('pages/authenticated/tips/dangerous-merchandise')
);
const ManageBusinessPage = lazy(
  () => import('pages/authenticated/business/manage-business')
);
const MonitoringPanel = lazy(
  () => import('pages/authenticated/monitoring-panel')
);
const InviteFriendPage = lazy(
  () => import('pages/authenticated/invite-friend')
);
const NotificationsCurrentAccountPage = lazy(
  () => import('pages/authenticated/notifications-cc')
);
const PrintExample = lazy(() => import('pages/authenticated/zebra'));

export default function AuthenticatedApp(): JSX.Element {
  const { defaultPyme } = usePyme();
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    if (window.location.pathname !== '/pyme-billing-info-form') {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, setShowModal]);

  return (
    <>
      <LocationProvider>
        <Suspense fallback={<WideLoader />}>
          {defaultPyme &&
          !defaultPyme?.has_billing_information &&
          window.location.pathname !== '/pyme-billing-info-form' ? (
            <>
              <Modal
                isOpen={showModal}
                toggle={() => {}}
                centered
                size='xl'
                backdrop='static'
              >
                <CurrentAccountBody />
              </Modal>
              <Route path='/pyme-billing-info-form'>
                <PymeBillingInfo />
              </Route>
            </>
          ) : (
            <Switch>
              <Route path='/dashboard'>
                <Dashboard />
              </Route>
              <Route path='/dashboard-b2c'>
                <DashboardB2C />
              </Route>
              <Route path='/business-members/:id'>
                <PymeMembers />
              </Route>
              <Route path='/pyme-billing-info-form'>
                <PymeBillingInfo />
              </Route>
              <Route path='/payment-order/:id'>
                <PaymentProvider>
                  <PaymentResume />
                </PaymentProvider>
              </Route>
              <Route path='/shipments'>
                <ShipmentsRoutes />
              </Route>
              <Route path='/new-business'>
                <NewBusinessPage />
              </Route>
              <Route path='/join-to-business'>
                <JoinToBusinessPage />
              </Route>
              <Route path='/manage-business'>
                <ManageBusinessPage />
              </Route>
              <Route path='/terms-and-conditions'>
                <TermsPage />
              </Route>
              <Route path='/frequent-question'>
                <FrequentQuestion />
              </Route>
              <Route path='/privacy-policies'>
                <PoliciesPage />
              </Route>
              <Route
                path={['/tracking/:os', '/tracking']}
                render={(props) => (
                  <TrackingProvider>
                    <Tracking {...props} />
                  </TrackingProvider>
                )}
              />
              <Route path='/new-shipping'>
                <NewShippingRoutes />
              </Route>
              <Route exact path='/price-quote'>
                <PriceQuotePage />
              </Route>
              <Route path='/price-quote/result'>
                <PriceQuoteResultPage />
              </Route>
              <Route path='/account'>
                <AccountRoutes />
              </Route>
              <Route path='/monitoring-panel'>
                <MonitoringPanel />
              </Route>
              <Route exact path='/tips'>
                <TipsPage />
              </Route>
              <Route exact path='/compensation'>
                <CompensationPage />
              </Route>
              <Route path='/b2c-emission'>
                <B2CEmissionsRoutes />
              </Route>
              <Route exact path='/problem-solution'>
                <ProblemSolutionPage />
              </Route>
              <Route exact path='/materials-request'>
                <MaterialsRequestPage />
              </Route>
              <Route path='/tips/dangerous-merchandise'>
                <DangerousPage />
              </Route>
              <Route path='/invite-friend'>
                <InviteFriendPage />
              </Route>
              <Route exact path='/'>
                <Redirect to='/dashboard' />
              </Route>
              <Route exact path='/zebra-print'>
                <ZebraProvider>
                  <PrintExample />
                </ZebraProvider>
              </Route>
              <Route exact path='/current-account-notifications'>
                <NotificationsCurrentAccountPage />
              </Route>
              <Route path='*'>
                <NotFound />
              </Route>
            </Switch>
          )}
        </Suspense>
        <Chatbot />
      </LocationProvider>
    </>
  );
}
