import { lazy, Suspense } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

import WideLoader from 'components/utils/wide-loader';

const B2CEmissionUnitaryPage = lazy(
  () => import('pages/authenticated/b2c-emission/unitary')
);

export default function B2CEmissionsRoutes(): JSX.Element {
  const { url } = useRouteMatch();

  return (
    <Suspense fallback={<WideLoader />}>
      <Switch>
        <Route exact path={`${url}/unitary`}>
          <B2CEmissionUnitaryPage />
        </Route>
      </Switch>
    </Suspense>
  );
}
