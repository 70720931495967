import { useCallback, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Row, Col } from '@bx-design/react-grid';
import { useHistory } from 'react-router-dom';
import { queryClient } from 'query-client';

import type { PymeType, UserType } from 'types/auth';
import { Modal, ModalBody } from 'components/ui-bx/modal';
import { Card, CardBody } from 'components/ui-bx/card';
import { newShippingModalAtom } from 'atoms/new-shipping/new';
import { Button } from 'components/ui-bx/button';
import { useAuth } from 'contexts/auth-context';
import styles from './modal.module.scss';
import imgUnitary from 'images/unitario.jpg';
import imgMulti from 'images/enviovarios.jpg';
import imgMassive from 'images/masivo.jpg';
import billingImg from 'images/factura.png';
import { usePyme } from 'contexts/pyme/pyme-context';
import { useIsNaturalPerson } from 'hooks/pyme/use-is-natural-person';
import NewShippingLayoutModalRut from './modal-rut';
import { CurrentAccountBody } from 'pages/authenticated/dashboard/current-account-body';

function NewShippingLayoutNewModal(): JSX.Element {
  const [newShippingModal, setNewShippingModal] = useAtom(newShippingModalAtom);
  const history = useHistory();
  const { user } = useAuth();
  const { isNaturalPerson } = useIsNaturalPerson();
  const { defaultPyme } = usePyme();
  const [billing, setBilling] = useState(
    defaultPyme ? defaultPyme.has_billing_information : ''
  );
  const [isTransparent] = useState(!billing ? false : true);
  const [modalSize, setModalSize] = useState<any>('xl');

  const handleClick = useCallback(
    (path?: string) => {
      if (path) {
        setNewShippingModal(false);
        setTimeout(() => history.push(path), 500);
      }
    },
    [history, setNewShippingModal]
  );

  const handleModalViewChange = (): void => {
    setModalSize('xl');
    setBilling(true);
  };

  useEffect(() => {
    if (!defaultPyme?.has_billing_information) {
      const userQuery = queryClient.getQueryData<UserType>('user');
      const noModalUser = userQuery?.pymes?.map((data: PymeType) => {
        return {
          ...data,
          has_billing_information: true,
        };
      });
      queryClient.setQueryData('user', {
        ...user,
        pymes: noModalUser,
      });
    } else {
      setModalSize('xl');
      setBilling(defaultPyme.has_billing_information);
    }
  }, [
    setBilling,
    user,
    newShippingModal,
    defaultPyme?.has_billing_information,
  ]);

  return (
    <Modal
      isOpen={newShippingModal}
      toggle={() => setNewShippingModal(false)}
      centered
      backdrop
      keyboard
      transparent={isTransparent}
      size={modalSize}
    >
      {!user?.pymes ? (
        <NewShippingLayoutModalRut />
      ) : !billing ? (
        <CurrentAccountBody />
      ) : isNaturalPerson || billing ? (
        <ModalBody>
          <Row alignItemsXl='stretch' justifyContentXl='center'>
            <Col col='12' xl='4'>
              <Card>
                <CardBody>
                  <div className={styles.wrapper}>
                    <h3 className={styles.title}>Envío unitario</h3>
                    <div className={styles.box}>
                      <div className={styles.imageBox}>
                        <img
                          className={styles.img}
                          src={imgUnitary}
                          alt='Envío unitario'
                        />
                      </div>
                    </div>
                    <div className={styles.text}>
                      Selecciona para crear un envío de forma manual. Con un
                      destino y origen y 1 pedido.
                    </div>
                    <div className={styles.box}>
                      <Button
                        onClick={() => handleClick('/new-shipping/unitary')}
                      >
                        Realizar envío
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col col='12' xl='4' hidden visibleXl>
              <Card>
                <CardBody>
                  <div className={styles.wrapper}>
                    <h3 className={styles.title}>2 a 5 envíos</h3>
                    <div className={styles.box}>
                      <div className={styles.imageBox}>
                        <img
                          className={styles.img}
                          src={imgMulti}
                          alt='2 a 5 envíos'
                        />
                      </div>
                    </div>
                    <div className={styles.text}>
                      Selecciona para crear más de 1 envío, cada uno con 1
                      origen, 1 destinatario , 1 pedido.
                    </div>
                    <div className={styles.box}>
                      <Button
                        onClick={() => handleClick('/new-shipping/multi')}
                      >
                        Realizar envíos
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col col='12' xl='4' hidden visibleXl>
              <Card>
                <CardBody>
                  <div className={styles.wrapper}>
                    <h3 className={styles.title}>Carga masiva</h3>
                    <div className={styles.box}>
                      <div className={styles.imageBox}>
                        <img
                          className={styles.img}
                          src={imgMassive}
                          alt='Carga masiva'
                        />
                      </div>
                    </div>
                    <div className={styles.text}>
                      Selecciona para importar entregas de 6 a 50 envíos, en una
                      hoja de cálculo excel. Puedes descargar la plantilla antes
                      de importar.
                    </div>
                    <div className={styles.box}>
                      <Button
                        onClick={() => handleClick('/new-shipping/massive')}
                      >
                        Importar envíos
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      ) : (
        <ModalBody>
          <div className={styles.billingWrapper}>
            <div className={styles.box}>
              <div className={styles.imageBilling}>
                <img className={styles.img} src={billingImg} alt='Billing' />
              </div>
            </div>
            <div className={styles.textBilling}>
              Si quieres recibir la factura de tus envíos, necesitamos que
              completes <br /> por única vez un formulario con los datos para la
              facturación. <br /> Si no los recuerdas, puedes optar por una
              boleta.
            </div>
            <br />
            <div className={styles.boxBilling}>
              <Button
                onClick={() => {
                  setNewShippingModal(false);
                  history.push('/pyme-billing-info-form');
                }}
              >
                Factura
              </Button>
              <Button
                onClick={() => {
                  handleModalViewChange();
                }}
              >
                Boleta
              </Button>
            </div>
          </div>
        </ModalBody>
      )}
    </Modal>
  );
}

export default NewShippingLayoutNewModal;
