import { FC } from 'react';

import styles from './footer.module.scss';

const Footer: FC = () => (
  <footer className={styles.footer}>
    <div className={styles.wrapper}>
      <div className={styles.text}>
        <b>© 2021 Blue Express</b> - Todos los derechos reservados
      </div>
      <div className={styles.mobileText}>
        <div>
          <b>© 2021 Blue Express</b>
        </div>
        <div>Todos los derechos reservados</div>
      </div>
    </div>
  </footer>
);

export default Footer;
