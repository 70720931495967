import { FC } from 'react';

type LogoBxProps = {
  width?: number;
  height?: number;
  inverted?: boolean;
  display?: string;
};

//{
// inverted = false,
// height,
// width,
// display,
//}
const LogoBx: FC<LogoBxProps> = () => {
  return (
    <svg
      width='26'
      height='37'
      viewBox='0 0 26 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.3775 18.8265C8.04757 18.8265 6.15088 16.8839 6.15088 14.4946C6.15088 12.1066 8.04624 10.1626 10.3775 10.1626C12.7087 10.1626 14.604 12.1052 14.604 14.4946C14.604 16.8839 12.7073 18.8265 10.3775 18.8265Z'
        fill='#4AC1E0'
      />
      <path
        d='M7.08313 3.55738C7.08313 1.5929 5.52899 0 3.61097 0C1.69428 0 0.140137 1.5929 0.140137 3.55738V24.0164H0.146801C0.249433 31.2049 5.9635 37 13.0011 37C20.1027 37 25.8595 31.0997 25.8595 23.821C25.8595 16.5792 20.1614 10.7036 13.1104 10.6434V17.7541C16.3307 17.8142 18.9231 20.5068 18.9231 23.821C18.9231 27.1735 16.272 29.8907 13.0011 29.8907C9.75956 29.8907 7.12845 27.2199 7.08313 23.9085V23.7336C7.08313 23.6872 7.08713 23.6421 7.08846 23.5956H7.07913L7.16843 12.0779L7.08313 3.55738Z'
        fill='#0033A1'
      />
      <path
        d='M10.3777 10.6409C12.4543 10.6409 14.1377 12.3663 14.1377 14.4947C14.1377 16.6231 12.4543 18.3485 10.3777 18.3485C8.30102 18.3485 6.61759 16.6231 6.61759 14.4947C6.61759 12.3663 8.30102 10.6409 10.3777 10.6409ZM10.3777 9.68457C7.79053 9.68457 5.68457 11.8417 5.68457 14.4947C5.68457 17.1477 7.78919 19.3048 10.3777 19.3048C12.9661 19.3048 15.0707 17.1477 15.0707 14.4947C15.0707 11.8417 12.9648 9.68457 10.3777 9.68457Z'
        fill='white'
      />
    </svg>
  );
};

export default LogoBx;
