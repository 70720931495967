import { forwardRef, ReactElement, ReactNode, HTMLAttributes } from 'react';
import cs from 'classnames';

import { Spinner } from 'components/ui-bx/spinner';
import ButtonContainer from './button-container';
import ButtonIcon from './button-icon';

export type ButtonType = 'button' | 'reset' | 'submit';
export type SizeType = 'sm' | 'md' | 'lg' | 'xl';
export type ButtonVariant = 'primary' | 'outline';

export type ButtonProps = {
  size?: SizeType;
  type?: ButtonType;
  disabled?: boolean;
  isActive?: boolean;
  variant?: ButtonVariant;
  isLoading?: boolean;
  icon?: ReactElement;
  animationHover?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
  children: ReactNode;
} & HTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size,
      type,
      disabled,
      isActive,
      variant,
      isLoading,
      icon,
      animationHover,
      fullWidth,
      onClick,
      children,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        disabled={disabled}
        type={type}
        onClick={onClick}
        className={cs(
          'btn',
          {
            'btn-primary': variant === 'primary',
            'btn-outline': variant === 'outline',
            'btn-sm': size === 'sm',
            'btn-md': size === 'md',
            'btn-lg': size === 'lg',
            'btn-xl': size === 'xl',
            'btn-is-loading': isLoading,
            'btn-fullwidth': fullWidth,
            'btn-animation': animationHover,
            'btn-is-active': isActive,
          },
          className
        )}
        {...props}
      >
        {!icon && !isLoading && <ButtonContainer>{children}</ButtonContainer>}
        {icon && !isLoading && (
          <ButtonContainer>
            <ButtonIcon>{icon}</ButtonIcon>
            {children}
          </ButtonContainer>
        )}
        {isLoading && (
          <ButtonContainer>
            <Spinner size={size} />
          </ButtonContainer>
        )}
      </button>
    );
  }
);

Button.defaultProps = {
  animationHover: true,
  size: 'md',
  variant: 'primary',
  isLoading: false,
};

Button.displayName = 'Button';

export default Button;
