import { FC } from 'react';

const SinoIcon: FC = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.1645 19.6236L20.4285 14.7352V9.76945C20.4285 4.66847 16.5117 0.523926 11.6925 0.523926C6.87331 0.523926 2.95652 4.66847 2.95652 9.76945V15.2086L0.959717 19.6236H7.37251C7.52611 22.0678 9.54211 24 11.9997 24C14.4573 24 16.4733 22.0582 16.6269 19.6236H23.1645ZM4.44451 15.5274V9.76945C4.44451 5.49931 7.69891 2.03103 11.6925 2.03103C15.6861 2.03103 18.9405 5.50897 18.9405 9.76945V15.1313L20.6109 18.1262H3.27332L4.44451 15.5274ZM11.9997 22.5026C10.3581 22.5026 9.01411 21.237 8.86051 19.6236H15.1389C14.9949 21.237 13.6413 22.5026 11.9997 22.5026Z'
        fill='#101010'
      />
    </svg>
  );
};

export default SinoIcon;
