import { createContext, ReactNode } from 'react';

import { useRegionsCache } from 'hooks/locations-cache/use-regions-cache';
import { useComunesCache } from 'hooks/locations-cache/use-communes-cache';
import { useAgenciesCache } from 'hooks/locations-cache/use-agencies-cache';
import WideLoader from 'components/utils/wide-loader';
import { usePopup } from 'hooks/popup/use-popup';

const LocationContext = createContext(null);

type Props = {
  children: ReactNode;
};

function LocationProvider({ children }: Props): JSX.Element {
  const { isLoading: regionIsLoading, isSuccess: regionIsSuccess } =
    useRegionsCache();
  const { isLoading: popupIsLoading } = usePopup();
  const { isLoading: communeIsLoading, isSuccess: communeIsSuccess } =
    useComunesCache();
  const { isLoading: agencyLoading, isSuccess: agencyIsSuccess } =
    useAgenciesCache();

  if (
    !regionIsLoading &&
    regionIsSuccess &&
    !communeIsLoading &&
    communeIsSuccess &&
    !agencyLoading &&
    agencyIsSuccess &&
    !popupIsLoading
  ) {
    return (
      <LocationContext.Provider value={null}>
        {children}
      </LocationContext.Provider>
    );
  }
  return <WideLoader />;
}

export default LocationProvider;
