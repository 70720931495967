import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import QueryClientProviderRoot from './query-client';

import 'react-toastify/dist/ReactToastify.min.css';
import '@bx-design/react-grid/dist/styles/bx-grid.min.css';
import 'react-nice-dates/build/style.css';

import 'utils/i18n';
import './styles/base.css';
import './styles/global.css';
import 'components/ui-bx/styles/ui-bx.scss';
import { Buffer } from 'buffer';

import AppProvider from './contexts/app-provider';
import App from './App';
import MDXProviderComponents from 'mdx/components';
import reportWebVitals from './reportWebVitals';
import { ddInit } from 'utils/dd';

ddInit();
global.Buffer = global.Buffer || Buffer;

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProviderRoot>
      <Router>
        <AppProvider>
          <MDXProviderComponents>
            <App />
          </MDXProviderComponents>
        </AppProvider>
      </Router>
    </QueryClientProviderRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
