import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function Chatbot(): JSX.Element | null {
  const location = useLocation();
  const [isLoaded, setIsloaded] = useState(false);
  useEffect(() => {
    const div = document.getElementById('uniq-sms-root');
    const otherS = document.getElementById('uniq-sms');
    const s = document.createElement('script');
    s.src = 'https://sms.uniq.ai/bot.js';
    s.setAttribute('data-app-id', '90c13d91-5005-4d3d-88c5-f88addba7572');
    s.setAttribute('data-id', 'uniq-sms');
    s.setAttribute('id', 'uniq-sms');
    const addScript = (): void => {
      setIsloaded(true);
      document.body.appendChild(s);
    };
    const removeScript = (): void => {
      s.remove();
      otherS?.remove();
      div?.remove();
      setIsloaded(false);
    };
    if (
      location.pathname === '/new-shipping/unitary' ||
      location.pathname === '/new-shipping/multi' ||
      location.pathname === '/new-shipping/massive' ||
      location.pathname === '/price-quote' ||
      location.pathname === '/b2c-emission/unitary'
    ) {
      removeScript();
    } else {
      if (!isLoaded) {
        addScript();
      }
    }
  }, [location, isLoaded]);
  return <></>;
}

export default Chatbot;
